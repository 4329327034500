import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import useQueryOnErrorHandler from 'core/helpers/queryOnErrorHandler/useQueryOnErrorHandler';
import { setPaymentPayPayload } from 'core/store/slice/paymentPay/paymentPaySlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useRouter } from 'next/navigation';

const useQueryHook = (queryKey: QueryKey, queryFn: QueryFunction, queryOptions: UseQueryOptions = {}, showError = true) => {
  const { onErrorHandler } = useQueryOnErrorHandler();
  const dispatch = useDispatch();
  const { push } = useRouter();
  const { paymentPayPayload } = useSelector((store: RootState) => store.paymentPayPayloadStore);

  return useQuery(queryKey, queryFn, {
    onError: (res: any) => {
      onErrorHandler(res, showError);
      dispatch(setPaymentPayPayload({ ...paymentPayPayload, isSuccess: false }));

      res.response.status === 404 && push('/404');
    },
    ...queryOptions,
  });
};

export default useQueryHook;
