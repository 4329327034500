import usePrivateRoute from 'core/hooks/usePrivateRoute';
import { NextIncomingMessage } from 'next/dist/server/request-meta';
import { ReadonlyHeaders } from 'next/dist/server/web/spec-extension/adapters/headers';

export const endPointUrls = Object.freeze({
  // authentication
  OTP: '/panel/auth/otp',
  CHECK_OTP: '/panel/auth/check',

  // links
  LINK: (username: string | number, req?: ReadonlyHeaders) => {
    // const isPrivate = usePrivateRoute(window.location.hostname);
    return `/link/site/links/${username}`;
  },
  TOP_LINK: '/link/site/links/top',
  PAYMENT_LINK_DETAIL: (paymentLinkId: string | number) => `/link/site/paymentLinks/${paymentLinkId}`,
  PAYMENT_GATEWAY_PAY: (paymentLinkId: string | number, query?: string) => `/link/site/payment/${paymentLinkId}/pay${query ? `?${query}` : ''}`,

  // plan
  PLAN_LIST: '/link/plans',
});
