import useApiCatcher from 'core/hooks/useApiCatcher';

const useQueryOnErrorHandler = () => {
  const apiCatcher = useApiCatcher();

  const onErrorHandler = (res: any, showError: boolean, payload?: any) => {
    showError && apiCatcher(res?.response.data?.errors || res?.response?.data?.message, undefined, payload);
  };

  return { onErrorHandler };
};

export default useQueryOnErrorHandler;
